html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 17, 16, 0.185),
    rgba(12, 8, 24, 0.377)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.137),
    rgba(12, 10, 22, 0.151)
  );

  --imp-text-color: #139bcc;
  font-size: 16px;
  height: 100%;
  margin: 0;
}

.heading {
  padding: 10px 20px 15px 20px !important; /* top right bottom left */
  font-size: 24px; /* Your desired font size */
}


/* Add margin to the top of the body content to separate it from the navbar */
body {
  margin-top: 4rem; /* Adjust the margin as needed for spacing */
  margin-bottom: 0;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}


/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--image-gradient);
  background-image: url(./Assets/front.png);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}










/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #18799c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00bbff;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00bbff;
  border-radius: 12px;
}






/* --------- */
/* Navbar Section  */
/* --------- */
@supports (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) {
  .sticky {
    background-color: #04152900;
    -webkit-backdrop-filter: blur(1px) !important;
    backdrop-filter: blur(5px) !important;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
  }
}

.sticky {
  background-color: #04152900;
  transition: all 0.3s ease-out !important;
  box-shadow: 0px 10px 10px 0px #041529b2 !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem; /* Top and bottom padding */
  font-size: 1.2rem !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}

.navbar-toggler {
  position: relative;
  background-color: transparent;
  border-color: transparent;
}

.navbar-toggler span {
  display: block;
  background-color: #139bcc;
  height: 4px;
  width: 27px;
  margin-top: 5px;
  margin-bottom: 5px;
  transform: rotate(1deg);
  opacity: 1;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: rotate(-45deg);
}


@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem; /* Increase the padding for nav bar */
    font-size: 1.4rem;
    background-color: #04152900;
  }

  .navbar-nav .nav-item a::after {
    display: none;
  }
}

@media (max-width: 319px) {
  .navbar {
    /* Adjust styles for devices with a width up to 319px */
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    background-color: #04152900;
  }

  .navbar-nav .nav-item a::after {
    display: none;
  }
}


.navbar-brand {
  color: rgb(250, 250, 250);
}

/* Logo Styling */
.logo {
  width: 5rem;
  height: auto;
  max-width: auto;
  display: block;
}

.navbar-nav .nav-link {
  font-size: 1.25rem;
  color: white !important;
  padding: 1rem 1rem 1rem 1rem; /* Increase the padding for nav links */
}

.nav-link {
  padding: 1rem 1rem; /* Increase the padding for nav links */
}

@media (max-width: 767px) {
  .nav-link {
    padding: 1rem; /* Increase the padding for nav links */
  }

  .logo {
    width: 5rem;
    height: auto;
    max-width: auto;
    display: block;
  }
}

@media (max-width: 319px) {
  .nav-link {
    /* Adjust styles for devices with a width up to 319px */
    padding: 0.8rem;
  }

  .logo {
    /* Adjust styles for devices with a width up to 319px */
    width: 4.5rem;
  }
}


.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
  /* margin-left: 0px; */
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  display: inline-block; /* Add this line */
}

.navbar-nav .nav-item a::after {
  content: "";
  position: absolute; /* Change this from relative to absolute */
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #139bcc;
  bottom: -1px; /* Adjust this value as needed */
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}



.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}







/* --------- */
/* Home section */
/* --------- */
/* .lion-wave {
  animation-name: lion-wave-animation;
  animation-duration: 2s; 
  animation-iteration-count: infinite;
  transform-origin: 50% 50%; 
  display: inline-block;
}

@keyframes lion-wave-animation {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px) rotate(-5deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(5px) rotate(5deg);
  }
}
 ** */


#about{
  padding: 1rem 1rem 1rem 1rem !important;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  text-align: center; /* Center text */
  display: flex;
}

#home > div.home-content.container{
  padding: 1rem 1rem 1rem 1rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#home > div.home-content.container > div{
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  text-align: center; /* Center text */
}

#home > div.home-content.container > div > div.home-header.col-md-7{
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#home > div.home-content.container > div > div.col-md-5{
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div{
  justify-content: center;
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 1rem 0rem 0rem 0rem !important;

}

#about > div > div:nth-child(1){
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div > div:nth-child(1) > div{
  padding: 6rem 1rem 2rem 1rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div:nth-child(1){
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 2rem 0rem 2rem !important;

}

#about > div:nth-child(1) > div{
  color: rgb(255, 255, 255) !important;
  font-size: 1.4em !important;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.onboard-section {
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  padding-top: 150px;
}

.onboard-description {
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 10px !important;
  width: 100vw;
}

.onboard-description > p:nth-child(2){
  font-size: 1.4rem !important;
}

  

@keyframes backgroundImageAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}


.home-content.container {
  padding: 1rem;
  margin: 0;
}

.home-content.container .row {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-content.container .home-header.col-md-7 {
  padding: 0;
  margin: 0;}


.home-section {
  position: relative;
  z-index: -1;
  background-image: url('./Assets/front.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  /* animation: backgroundImageAnimation 5s ease-in-out; */
  animation-fill-mode: forwards;
}
  
.wave-container {
  aspect-ratio: 960/540;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  bottom: 0;
  opacity: 100;
}

  
.waveSVG {
  position: absolute;
  background-image: url("./Assets/wave.svg");
  transform: scaleY(-1);
  opacity: 100;
  
}
@media (max-width: 767px) {
  .home-section {
    background-size: cover;
    background-position: center right 15%;
  }

  .wave-container {
    aspect-ratio: 960/540;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    bottom: 0;
    width: 100%;
    opacity: 100;
  }
  
  .waveSVG {
    position: absolute;
    background-image: url("./Assets/wave.svg");
    transform: scaleY(-1);
  }
}

@media (max-width: 319px) {
  /* Adjust styles for devices with a width up to 319px */
  .home-section {
    background-size: contain;
    background-position: center;
  }

  .wave-container {
    /* Adjust styles for devices with a width up to 319px */
    aspect-ratio: 480/270;
  }
}






.home-content {
  color: whitesmoke;
}

.heading {
  font-size: 1.5rem !important;
}


.heading-name {
  font-size: calc(1.2rem + 3vw) !important;
  font-weight: 200;
}

.main-name {
  color: #38bcec;
  font-weight: 900;
  
}

.Typewriter__wrapper {
  color: #ffffff !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.Typewriter__cursor {
  display: inline-block !important;
  color: #ffffff !important;

  padding-top: 2rem !important;
}

.Typewriter__wrapper,
.Typewriter__cursor {
  font-size: calc(1rem + 1vw);
  line-height: normal;
}


@media (max-width: 767px) {
  .Typewriter__wrapper {
    color: #ffffff !important;
    font-weight: 700 !important;
    line-height: normal !important;
    text-align: center !important;
    justify-content: center !important; 
    align-items: center !important;
    
  }
  
  .Typewriter__cursor {
    display: inline-block !important;
    color: #ffffff !important;
  }
  
  .Typewriter__wrapper,
  .Typewriter__cursor {
    font-size: calc(1rem + 1vw);
    line-height: normal;
  }

  .home-about-section {
    padding-bottom: 50px !important;
    padding-top: 50px !important;
  }

  .home-about-description {
    padding-top: 50px !important;
    padding-bottom: 20px !important;
    text-align: center !important;
    width: 100vw;
  }

  .home-about-body {
    padding-top: 30px;
    text-align: center;
  }
}

.myAvtar {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Align to the bottom */
}


@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

@media (max-width: 767px) {
  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
}

.home-about-social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
  font-size: 30px !important;
  line-height: 45px !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  background: rgba(255, 255, 255, 0.938) !important;
  justify-content: center;


}

@media (max-width: 767px) {
  .home-social-icons {
        position: relative !important;
        display: inline-block !important;
        width: 40px !important;
        height: 40px !important;
        font-size: 30px !important;
        text-align: center !important;
        line-height: 45px !important;
        background: rgba(255, 255, 255, 0.972) !important;border-radius: 50% !important;
        transition: 0.5s !important;
        justify-content: center;
        padding-inline-start: 0 !important;
  }
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}



.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}













/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative;
  padding: 150px 0 30px;
  background-image: var(--section-background-color);
  color: white;
  display: flex;
}

#scroll > div.about-section.container-fluid > div.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#scroll > div.about-section.container-fluid > div.container > div:nth-child(1){
  align-items: center;
  justify-content: center;
  width: 100%;
}

#scroll > div.about-section.container-fluid > div.container > div:nth-child(2){
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Tech Icons */
.tech-icons {
  font-size: 4.75em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #06749c !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 0 4px 5px 3px #06749c !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  position: relative;
}

/* Responsive Styling for Tech Icons */
@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

/* Hover Effect on Tech Icons */
.tech-icons:hover {
  transform: scale(1.05) !important;
  border: 2.2px solid #0897ca !important;
  box-shadow: 0 4px 4px 5px #0897ca;
}

/* Display Tech Name on Hover */
.tech-icons:hover::before {
  content: attr(techname);
  position: absolute;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 15px;
  padding: 5px;
  border-radius: 4px;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

}

/* Quote Card Styling */
.quote-card-view {
  border: none !important;
  background-color: transparent !important;
}

/* Styling for Activities List */
.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

/* Responsive Styling for About Image */
@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}



.ContactUs {
  align-items: center;
  align-content: center;
  max-width: 100vw;
  padding: 20px;
  background-color: var(--section-background-color);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.ContactUs form {
  padding: 1em 1em 1em 1em;
}

.ContactUs label {
  display: block;
  margin-bottom: 5px;
}

.ContactUs input[type="text"],
.ContactUs input[type="email"],
.ContactUs input[type="tel"],
.ContactUs select {
  color: #fff;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: var(--image-gradient);
}

.ContactUs textarea {
  width: 100%;
  color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: var(--image-gradient);
}

.ContactUs button[type="submit"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  background-color: var(--bs-primary);
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .ContactUs {
    max-width: 100%;
  }

  .ContactUs form {
    padding: 1em 1em 1em 1em;
  }

  .ContactUs input[type="text"],
  .ContactUs input[type="email"],
  .ContactUs input[type="tel"],
  .ContactUs select,
  .ContactUs textarea {
    margin-bottom: 15px;
  }

  .ContactUs button[type="submit"] {
    width: 100%;
  }
}



/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  /* background-image: var(--section-background-color) !important; */
}

.project-card {
  padding: 10px 25px 50px;
}

.project-card-view {
  padding: 0 !important;
  box-shadow: 0 4px 5px 3px #06749c !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 1.5 !important;
  transition: all 0.5s ease 0s !important;
  overflow: hidden !important;
}

.project-card-view:hover {
  transform: scale(1.1) !important;
  box-shadow: 0 4px 4px 5px #0897ca !important;
}


.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px #06749c !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  width: auto !important;
}

.blog-card-view-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
  padding: 20px; /* Adjust as needed */
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px #0897ca!important;
}


/*Login*/
/* .login-heading {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  text-align: center !important;
} */

.login-image-size {
  width: 100%;
  height: auto;
  max-height: 600px; /* Adjust this value to suit your needs */
}


.login-container {
  padding-top: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
}

.login-items {
  display: flex;
  flex-direction: row;
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-header {
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.login-form-group label {
  margin-bottom: 0.5rem;
}

.login-form-group input {
  width: 100%;
  padding: 0.5rem;
}

.forgot-password-link {
  display: inline-block;
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
}


/* Error Message Style */
.error-message {
  color: #d32f2f; /* Red color for errors */
  background-color: #ffebee; /* Light red background */
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #f44336; /* Slightly darker red border */
}

/* .login-toggle {
  display: inline-block;
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
  background: "none" !important;
  border: "none"!important;
  padding: "0";
  font: "inherit";
} */
.login-toggle:hover, .login-toggle:focus {
  color: #215fbb; /* Change to your desired hover color */
}

/* Login Button */
.login-button {
  background: linear-gradient(to right, #4174ff, #2baaff);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}
.login-button:hover {
  background: linear-gradient(to right, #2ba0ff, #41a3ff);
}



/* Google Sign-In Button */
.google-login-button {
  background: transparent;
  color: #41f2ff;
  border: 2px solid #4441ff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.google-login-button:hover {
  background-color: #207a96;
  color: white;
}





.login-footer {
  margin-top: 1rem;
  text-align: center;
}

.login-image {
  width: 100%;
  max-height: 600px;
  overflow: hidden;
}

.image {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 992px) {
  .login-items {
    flex-direction: row;
  }

  .login-content,
  .login-image {
    flex: 1;
  }
}



/*Blog Post */
.blog-post-card {
  padding: 0rem 0rem 1.5rem 0rem !important;
  height: auto !important;
  width: auto !important; /* Adjust as needed */
}

.blog-post-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  display: flex !important; /* Makes the container a flex container */
  flex-direction: column !important; /* Stacks the items vertically */
  align-items: center !important; /* Centers the items vertically */
  justify-content: center !important; /* Centers the items horizontally */
  width: auto !important; /* Adjust as needed */
  height: auto !important; /* Adjust as needed */
  margin: 8rem 2rem 3rem 2rem;
  padding: 1rem 3rem 3rem 3rem;
}

.blog-post-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.blog-post-content {
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}

.blog-post-data {
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}






.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #06749c !important;
  border-color: #06749c !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #1ca6d8 !important;
  border-color: #1ca6d8 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;

  padding-top: 10px !important;
}

#scroll > div.blogs-section.container-fluid {
  padding: 150px 12px 30px 12px !important;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > p:nth-child(1) {
  margin: 0;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > p:nth-child(2) {
  margin: 0;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > div {
  margin-top: 1rem;
  font-size: calc(1rem + 0.4vw);
  text-align: justify;
}


/* Ensures the main container takes up the full height of the viewport */
.agent-view {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  overflow: hidden; /* Prevents scrolling */
}

.agent-view1 {
  margin-top: 120px;
  position: relative;
  overflow: auto; /* Allows internal scrolling if necessary */
}



/* --------- */
/* Footer */
/* --------- */
.footer {
  bottom: 0;
  background-color: #041529;
  padding: 10px 0 10px 0;
  text-align: center;
  color: white;
}

.footer-copywright, .footer-body {
  text-align: center;
}

@media (max-width: 767px) {
  .footer-copywright, .footer-body {
    text-align: center;
  }
}

.footer h3 {
  font-size: 1em;
  color: white;
  margin: 0.5em 0;
}

.footer-icons {
  margin: 0.5em 0;
  padding: 0;
}

.blockquote-footer {
  color: #a588c0;
}
