.App {
  text-align: center;
}

/* Additional styles for layout */
.body-flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}

/* Footer */
.footer {
  bottom: 0;
  background-color: #041529;
  padding: 10px 0 10px 0;
  text-align: center;
  color: white;
}

.footer-copywright, .footer-body {
  text-align: center;
}

@media (max-width: 767px) {
  .footer-copywright, .footer-body {
    text-align: center;
  }
}

.footer h3 {
  font-size: 1em;
  color: white;
  margin: 0.5em 0;
}

.footer-icons {
  margin: 0.5em 0;
  padding: 0;
}

.blockquote-footer {
  color: #a588c0;
}
